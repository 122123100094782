<app-breadcrumb [title]="'Cart'" [breadcrumb]="'Cart'"></app-breadcrumb>
<!-- section start -->
<section class="cart-section section-b-space">
    <div class="container">
        <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!_global.CARTSUMMARY.length">
        <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
        <h3><strong>Your Cart is Empty</strong></h3>
        <h4>Add something to make me happy :)</h4>
        </div>
        <div class="row" *ngIf="_global.CARTSUMMARY.length">
            <div class="col-sm-12">
                <table class="table cart-table table-responsive-xs">
                    <thead>
                        <tr class="table-head">
                            <th scope="col">image</th>
                            <th scope="col">product name</th>
                            <th scope="col">price</th>
                            <th scope="col">quantity</th>
                            <th scope="col">total</th>
                            <th scope="col">action</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let cart of _global.CARTSUMMARY">
                        <tr>
                            <td>
                                <a [routerLink]="['/product/'+ cart.pid]">
                                    <img alt="{{cart.pname}} {{cart.variant.title}}" title="{{cart.pname}} {{cart.variant.title}}" [src]="cart.variant.image.src">
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/product/'+ cart.pid]">{{cart.pname}} - {{cart.variant.title}}</a>
                                <div class="mobile-cart-content row">
                                    <div class="col">
                                        <div class="qty-box">
                                            <div class="input-group">
                                                <input type="text" name="quantity" class="form-control input-number" disabled [(ngModel)]="cart.quantity">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <h2 class="td-color">
                                            $ {{ cart.quantity|common:['totalproductcost',cart.variant.price] | number : '1.2-2' }}
                                        </h2>
                                    </div>
                                    <div class="col">
                                        <h2 class="td-color">
                                            <a (click)="remove(cart.variant.id);stopClickPropagate($event)" class="icon">
                                                <i class="ti-close"></i>
                                            </a>
                                        </h2>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h2>
                                    $ {{cart.variant.price.amount}}
                                </h2>
                            </td>
                            <td>
                                <div class="qty-box">
                                    <div class="input-group">
                                        <span class="input-group-prepend">
                                            <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decreaseQuantity(cart.variant,cart.quantity,cart.pid,cart.pname)">
                                                <i class="ti-angle-left"></i>
                                            </button>
                                        </span>
                                        <input type="text" name="quantity" class="form-control input-number" disabled [(ngModel)]="cart.quantity">
                                        <span class="input-group-prepend">
                                            <button type="button" class="btn quantity-right-plus"  data-type="plus" (click)="increaseQuantity(cart.variant,cart.quantity,cart.pid,cart.pname)">
                                                <i class="ti-angle-right"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h2 class="td-color">
                                    $ {{ cart.quantity|common:['totalproductcost',cart.variant.price.amount] | number : '1.2-2' }}
                                </h2>
                            </td>
                            <td>
                                <a href="javascript:void(0)" (click)="remove(cart.variant.id);stopClickPropagate($event)" class="icon">
                                    <i class="ti-close"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table cart-table table-responsive-md">
                    <tfoot>
                        <tr>
                            <td>total price :</td>
                            <td>
                                <h2>$ {{total | number : '1.2-2' }}</h2>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="row cart-buttons" *ngIf='_global.CARTSUMMARY.length'>
            <div class="col-6">
                <a [routerLink]="['/home']" class="btn btn-solid">continue shopping</a>
            </div>
            <div class="col-6">
                <a class="btn btn-solid" (click)="checkout(); stopClickPropagate($event)">Proceed to check out</a>
            </div>
        </div>
    </div>
</section>
<!--section end-->