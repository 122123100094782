<div class="box-view">
    <div class="img-wrapper">
      <div class="lable-block">
        <span class="lable3" *ngIf="product.new">new</span>
        <span class="lable4" *ngIf="product.sale">on sale</span>
      </div>
      <div class="front">
        <a>
          <img alt="{{ product.name }}" title="{{ product.name }}" [defaultImage]="product.pathToMedia.src ? product.pathToMedia.src : 'assets/images/product/placeholder.jpg'" [lazyLoad]="product.pathToMedia.src ? product.pathToMedia.src : 'assets/images/product/placeholder.jpg'" class="img-fluid lazy-loading image-height-constant"/>
        </a>
      </div>
      <!-- <div class="back" *ngIf="onHowerChangeImage">
        <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
          <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
        </a>
      </div> -->
      <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
        <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
          <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
            <img [lazyLoad]="image.src">
          </a>
        </li>
      </ul> -->
      <div class="cart-info cart-wrap">
        <a href="javascript:void(0)" title="Quick View">
          <i class="ti-search" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div class="product-detail">
      <div>
        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
        <a [routerLink]="['/products/details', {productId: product.id}]">
            <h6>{{ product?.name | titlecase }}</h6>
        </a>
        <p [innerHTML]="product?.description | common: ['limitChar', 500,'...']"></p>
        <h4>
            {{ product?.discountPrice | currency:product?.currency:'symbol' }}
            <del *ngIf="product?.basePrice"><span class="money"> {{ product?.basePrice | currency:product?.currency:'symbol' }}</span></del>
        </h4>
        <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
          <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
            (click)="ChangeVariants(color, product)">
          </li>
        </ul> -->
      </div>
    </div>
  </div>
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  <!-- <app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
  <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->
  
  