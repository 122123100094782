<app-breadcrumb [title]="'About Us'" [breadcrumb]="'About Us'"></app-breadcrumb>
<!-- about section start -->
<section class="section-b-space" *ngIf="dataLoding">
    <app-royal-loading></app-royal-loading>
</section>
<section class="about-page section-b-space" *ngIf="!dataLoding">
    <div class="container">
        <div class="row">
            <div [innerHTML]="page_content"></div>
        </div>
    </div>
</section>
<!-- about section end -->
<!-- service section -->
<div class="container" *ngIf="!dataLoding">
    <section class="service border-section small-section mb-5">
      <app-services></app-services>
    </section>
  </div>
<!-- service section end -->