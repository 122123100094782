
<app-breadcrumb [title]="pageTitleName" [breadcrumb]="pageTitleName"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <!-- <div class="loading-section" *ngIf="dataLoding">
                <div class="clock-loader"></div>
                <div class="loading-text">Loading...</div>
            </div> -->
            <app-royal-loading *ngIf="dataLoding"></app-royal-loading>
            <div class="row" *ngIf="!dataLoding">
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12" *ngIf="products.length !== 0">
                                <div class="collection-product-wrapper" id="products">
                                    <app-grid [products]="products" [paginate]="totalRecord" [layoutView]="layoutView" [sortBy]="sortBy" (setGrid)="updateGridLayout($event)" (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)"></app-grid>
                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of products">
                                                <div class="product-box">
                                                    <app-product-list-view [product]="product" [currency]="" [thumbnail]="false" [cartModal]="true"></app-product-list-view>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <app-pagination [products]="products" [paginate]="totalRecord" (setPage)="setPage($event)"></app-pagination> -->
                                    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000" (scrolled)="onScroll()" *ngIf="products.length">
                                    </div>
                                    <div class="product-infinitescroll">
                                        <div class="theme-paggination-block">
                                            <div class="container-fluid p-0">
                                                <div class="row">
                                                    <div class="text-center col-sm-12">
                                                        <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                                                            <img src="assets/images/loader.gif" *ngIf="!finished && products.length">
                                                            <div class="loading-more active" *ngIf="finished && products.length">No More Products</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12" *ngIf="products.length === 0">
                                <div class="error-section">
                                    <h2 class="error-message">{{'Sorry, there are no products in this collection' | translate}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->