import { Injectable } from '@angular/core';
import { GlobalService } from '../_common/global';
import { ApiService } from '../_services/api.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private _global: GlobalService, private api: ApiService) { }
  call(url, params) {
    console.log("api params", params);
  }

  allCategoryType() {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName',  this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('application', 'category');
    params = params.append('categoryType', 'product');
    return this.api.call('category/all', params);
  }

  allProducts() {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName',  this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('application', 'category ');
    params = params.append('start', '0');
    params = params.append('rows', '20');
    params = params.append('category', 'product');
    params = params.append('component','Social+Ecommerce');
    return this.api.call('service/search/category', params);
  }

  sheets(obj) {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName',  this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('application', 'product');
    params = params.append('start', '0');
    params = params.append('rows', '12');
    params = params.append('category', 'Sheets');
    params = params.append('component','Social+Ecommerce');
    return this.api.call('service/search/product', params);
  }

  comforters(obj) {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('application', 'product');
    params = params.append('start', '0');
    params = params.append('rows', '12');
    params = params.append('category', 'Comforters');
    params = params.append('component','Social+Ecommerce');
    return this.api.call('service/search/product', params);
  }
  bath(obj) {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName',  this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('application', 'product');
    params = params.append('start', '0');
    params = params.append('rows', '12');
    params = params.append('category', 'Bath');
    params = params.append('component','Social+Ecommerce');
    return this.api.call('service/search/product', params);
  }

  accessories(obj) {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName',  this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('application', 'product');
    params = params.append('start', '0');
    params = params.append('rows', '12');
    params = params.append('category', 'Accessories');
    params = params.append('component','Social+Ecommerce');
    return this.api.call('service/search/product', params);
  }
}

