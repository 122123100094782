import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../_services/api.service';
import { GlobalService } from '../_common/global';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(private _global: GlobalService, private api: ApiService) { }

  list(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('token', this._global.getToken());
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('application', 'page');
    return this.api.call('service/search/page', params);
  }
}
