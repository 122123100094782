import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GlobalService } from '../_common/global';
import { ApiService } from '../_services/api.service';
@Injectable()
export class LoginService {

  constructor(private _global: GlobalService,private api: ApiService) { }

  login(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
        params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('sess_cookie', this._global.getToken());
    params = params.append('token', this._global.getToken());
    return this.api.call('member/login',params);
  }

  duplicate(obj) {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('sess_cookie', this._global.getToken());
    params = params.append('token', this._global.getToken());
   
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
        params = params.append(key, v);
    });
    return this.api.call('member/duplicate',params);
  }

  admin(email, password) {
    let params = new HttpParams();
    params = params.append('email', email);
    params = params.append('password', password);
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('sess_cookie', this._global.getToken());
    params = params.append('token', this._global.getToken());
    return this.api.call('user/login',params);
  }

  validate(email) {
    let params = new HttpParams();
    params = params.append('email', email);
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('sess_cookie', this._global.getToken());
    params = params.append('token', this._global.getToken());
    return this.api.call('member/duplicate',params);
  }
}