import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Product } from "../../classes/product";
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import { GlobalService } from 'src/app/_common/global';
import { ApiService } from 'src/app/_services/api.service';
import { BrandsService } from 'src/app/_services/brands.service';
import { ShopifyService } from 'src/app/_services/shopify.service';
import { ProductService } from 'src/app/_services/product.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public productsOld: Product[] = [];
  public search: boolean = false;
  public products: any = [];
  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }];
  public brands: any = [];
  public isMember = false;
  suggest_list: any = [];
  searchtxt = "";
  productview: any = [];
  product_list: any = [];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public local: LocalStorageService,
    public session: SessionStorageService,
    private _brandsService: BrandsService,
    private _shopifyService: ShopifyService,
    private _productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    public _global: GlobalService,
    private _apiService: ApiService
  ) {
    this.isMember = this._global.isMember();
    this._global.CARTSUMMARY = this._global.cartItems();
    let brand_item = this.local.get(this._global.SESSION_KEY + '_brands');
    if (brand_item != null && brand_item['brands']) {
      this.brands = brand_item['brands'];
    } else {
      // this._brandsService.brand({}).subscribe((data) => {
      //   if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
      //     this.brands = data["response"]["docs"];
      //     let item = { brands: this.brands };
      //     this.local.set(this._global.SESSION_KEY + '_brands', item, 60, 'm');
      //   }
      // }, error => {
      //   console.log("error", JSON.stringify(error));
      // });
    }
    // this.productService.cartItems.subscribe(response => this.productsOld = response);
  }

  ngOnInit(): void {
  }

  get total(): number {
    if (this._global.CARTSUMMARY.length){
      const totVal = this._global.CARTSUMMARY.map(lineItem => lineItem.quantity * (+lineItem.variant.price.amount)).reduce((prev, next) => prev + next);
      return totVal;
    } else {
      return 0;
    }
  }

  get totalItem(): number {
    if (this._global.CARTSUMMARY.length) return this._global.CARTSUMMARY.map(lineItem => lineItem.quantity).reduce((prev, next) => prev + next);
    else return 0;
  }

  searchToggle() {
    this.search = !this.search;
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  closeSubMenus() {
  }

  remove(varientId) {
    if (this._global.CARTSUMMARY.length) {
      let item_list = [];
      let cart = localStorage.getItem(this._global.CART_STORAGE);
      if (cart !== undefined && cart !== null) {
        let cart_list = JSON.parse(cart);
        Object.entries(cart_list).forEach(([key, value]) => {
          if (value["variantId"] != varientId) {
            item_list.push(value);
          }
        });
      }
      localStorage.setItem(this._global.CART_STORAGE, JSON.stringify(item_list));
      this._global.CARTSUMMARY = this._global.cartItems();
      this._global.showMessage({ "type": "success", "message": "Product has been removed from your cart", "title": "" });
    }
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  clear() {
    localStorage.setItem(this._global.CART_STORAGE, JSON.stringify([]));
    this._global.CARTSUMMARY = this._global.cartItems();
  }

  searchProduct() {
    let params = {
      name: this.searchtxt,
      tag: this.searchtxt
    };
    let p = {
      start: 0,
      rows: 10
    }
    this.product_list = [];
    if (this.searchtxt.length > 3) {
      this._productService.product(p).subscribe(
        data => {
          let items = this._global.getVal(data, 'response', []);
          if (items.length > 0) {
            Object.entries(items).forEach(([key, value]) => {
              this.product_list[key] = value['doc'];
            });
          } else {
            this.product_list = [];
          }

        },
        error => {
          console.log("error", JSON.stringify(error));
        });
    }
  }

  gotoProductView(item) {
    this.router.navigate(['/products/view', item.id]);
  }

  suggestProduct(event: any) {
    this.suggest_list = [];
    if (event.keyCode == 13) {
      this.getProduct(this.searchtxt);
    } else {
      let p = {
        start: 0,
        rows: 10,
        suggestText: this.searchtxt
      }
      if (this.searchtxt.length > 3) {
        this._productService.suggest(p).subscribe(
          data => {
            let items = this._global.getVal(data, 'response', []);
            if (items.length > 0) {
              Object.entries(items).forEach(([key, value]) => {
                this.suggest_list[key] = value;
              });
            } else {
              this.suggest_list = [];
            }

          },
          error => {
            console.log("error", JSON.stringify(error));
          });
      }
    }
  }

  getProduct(searchText) {
    let tags = searchText.split(" ");
    let params = {
      adminTag: 'duvets,accessories,bath,bedding',
      searchOperator: 'OR',
    };
    if (tags.length > 0) {
      params['tag'] = tags[0];
      params['name'] = tags[0];
    }
    this.router.navigate(['/products/view', params]);
    //this.router.navigate(['/products/view/', product["id"]]);
    console.log("search product for ", searchText);
    this.searchtxt = '';
    this.searchToggle();
  }
  
  checkout() {
    let cart = localStorage.getItem(this._global.CART_STORAGE);
    let lineItems = JSON.parse(cart);
    this._shopifyService.createCheckout(lineItems).then(
      ({ model, data }) => {
        if (!data.checkoutCreate.userErrors.length) {
          this.openCheckout(data.checkoutCreate.checkout);
          let i = 0;
          data.checkoutCreate.checkout.lineItems.edges.forEach(edge => {
            i++;
          });
        } else {
          data.checkoutCreate.userErrors.forEach(error => {
            alert(JSON.stringify(error));
          });
        }
      }, err => alert(err)
    );
  }

  openCheckout(checkout) {
    window.open((checkout.webUrl).replace('www.royalluxurylinen.com', this._global.CHEKOUT_URL) + this._global.getCheckoutCustomerDetails(), '_self');
  }

}
