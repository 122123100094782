import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalService } from 'src/app/_common/global';
import { BlogService } from 'src/app/_services/blog.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-royal-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoyalBlogComponent implements OnInit {
  public blogsList: any = [];
  public pageno = 1;
  public perPage = 12;
  public currentPage = 0;
  public start = 0;
  public totalRecord = 0;
  public totalItem: Number = 0;
  public selectedproduct: any;
  public loading: false;
  public dataLoding = false;
  public params: any = [];
  public pageCounter = 1;
  
  constructor(
    private _blogService: BlogService,
    private _global: GlobalService
  ) {
    let p = {start: 0, rows: this.perPage, sortOrder: 'desc', sortString: 'createdTime'};
    this.loadAppData(p);
  }

  ngOnInit() {
  
  }

  loadAppData(p) {
    this.dataLoding = true;
    this._blogService.list(p).subscribe(blogList => {
      console.log(blogList);
      this.dataLoding = false;
      if (!_.isEmpty(this._global.getVal(blogList, 'response.docs', ''))) {
      this.blogsList = blogList["response"]["docs"];
      this.totalRecord = this._global.getVal(blogList, 'response.numFound', '0');
      }
      console.log(this.blogsList);
    },
    error => {
      console.log("error", JSON.stringify(error));
    });
}

  onScrollDown() {
    if (!this.dataLoding) {
      this.dataLoding = true;
      if (this.totalRecord <= this.pageCounter * this.perPage) {
        this.dataLoding = false;
      } else {
        let p = this._global.generateParams(this.params, ['component', 'start', 'language', 'url', 'dialect']);
        p['start'] = this.pageCounter * this.perPage;
        this._blogService.list(p).subscribe(
          data => {
            if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
              this.blogsList = this.blogsList.concat(data["response"]["docs"]);
              this.dataLoding = false;
              this.pageCounter += 1;
              this.params = this._global.getVal(data, 'request', '');
            } else {
              this.dataLoding = false;
            }
          },
          error => {
            console.log("error", JSON.stringify(error));
          });

      }
    }
  }

}