import { Component } from '@angular/core';

@Component({
  selector: 'app-royal-loading',
  templateUrl: './royal-loading.component.html',
  styleUrls: ['./royal-loading.component.scss']
})
export class RoyalLoadingComponent {

}
