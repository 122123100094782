import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorageService, SessionStorageService} from 'angular-web-storage';
import { GlobalService } from 'src/app/_common/global';
import { BrandsService } from 'src/app/_services/brands.service';
import { CampaignService } from 'src/app/_services/campaign.service';
import { ReviewService } from 'src/app/_services/review.service';
import { Product } from 'src/app/shared/classes/product';
import { ProductSliderWithArrow } from 'src/app/shared/data/slider';
// import { ProductService } from 'src/app/shared/services/product.service';
import { BlogService } from 'src/app/_services/blog.service';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/_services/product.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public products: Product[] = [];
  public productCollections: any[] = [];
  public active;
  public pageLoad = false;
  public brands: any = [];
  public ProductSliderConfig: any = ProductSliderWithArrow;
  public campaigns: any = [];
  public isMember = false;
  public reviews: any = [];
  public blogsList: any;
  public isLoadingProduct = false;
  public sliders = [{
    title: 'welcome to',
    subTitle: 'Royal luxury linen',
    image: 'assets/images/slider/slider1.jpg',
    btnObj: [{
      btnName: 'Comforters & Duvets',
      link: {adminTag :'bedding', category:'Bedding~COMFORTERS'}
    }, {
      btnName: 'Sheets & Pillowcases',
      link: {adminTag:'bedding',category:'Sheets'}
    }]
  }, {
    title: 'welcome to',
    subTitle: 'Royal luxury linen',
    image: 'assets/images/slider/slider2.jpg',
    btnObj: [{
      btnName: 'Sheets',
      link: {adminTag :'duvets', category:'bed-skirts'}
    }, {
      btnName: 'Pillowcases',
      link: {adminTag :'duvets', category:'Accessories~PILLOW'}
    }]
  }, {
    title: 'welcome to',
    subTitle: 'Royal luxury linen',
    image: 'assets/images/slider/slider3.jpg',
    btnObj: [{
      btnName: 'Rugs',
      link: {adminTag :'bath', category:'Bath~RUGS'}
    }]
  }, {
    title: 'welcome to',
    subTitle: 'Royal luxury linen',
    image: 'assets/images/slider/slider4.jpg',
    btnObj: [{
      btnName: 'Bed Skirts',
      link: {adminTag :'duvets', category:'bed-skirts'}
    }, {
      btnName: 'Pillow & Shams',
      link: {adminTag :'duvets', category:'Accessories~PILLOW'}
    }]
  }]

  // Collection banner
  public collections = [{
    image: 'assets/images/collection/fashion/bedding.jpg',
    save: 'save 10%',
    title: 'Bedding',
    className: 'p-left',
    className1: 'text-start',
    btnObj: [{
      btnName: 'Comforters & Duvets',
      link: {adminTag :'bedding', category:'Bedding~COMFORTERS'}
    }, {
      btnName: 'Sheets & Pillowcases',
      link: {adminTag:'bedding',category:'Sheets'}
    }]
  }, {
    image: 'assets/images/collection/fashion/beedroom.jpg',
    save: 'save 10%',
    title: 'Bedroom',
    className: 'p-right',
    className1: 'text-end',
    btnObj: [{
      btnName: 'Sheets',
      link: {adminTag :'duvets', category:'bed-skirts'}
    }, {
      btnName: 'Pillowcases',
      link: {adminTag :'duvets', category:'Accessories~PILLOW'}
    }]
  }, {
    image: 'assets/images/collection/fashion/accessories.jpg',
    save: 'save 10%',
    title: 'Accessories',
    className: 'p-left',
    className1: 'text-start',
    btnObj: [{
      btnName: 'Bed Skirts',
      link: {adminTag :'duvets', category:'bed-skirts'}
    }, {
      btnName: 'Pillow & Shams',
      link: {adminTag :'duvets', category:'Accessories~PILLOW'}
    }]
  }, {
    image: 'assets/images/collection/fashion/bath.jpg',
    save: 'save 10%',
    title: 'Bath',
    className: 'p-right',
    className1: 'text-end',
    btnObj: [{
      btnName: 'Rugs',
      link: {adminTag :'bath', category:'Bath~RUGS'}
    }]
  }];

  // Blog
  public blogs = [{
    image: 'assets/images/blog/1.jpg',
    date: '25 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/2.jpg',
    date: '26 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/3.jpg',
    date: '27 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/4.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }];

  // Logo
  public logo = [{
    image: 'assets/images/logos/1.png',
  }, {
    image: 'assets/images/logos/2.png',
  }, {
    image: 'assets/images/logos/3.png',
  }, {
    image: 'assets/images/logos/4.png',
  }, {
    image: 'assets/images/logos/5.png',
  }, {
    image: 'assets/images/logos/6.png',
  }, {
    image: 'assets/images/logos/7.png',
  }, {
    image: 'assets/images/logos/8.png',
  }];

  constructor(
    public local: LocalStorageService,
    // public session: SessionStorageService,
    // public productService: ProductService,
    private _reviewService: ReviewService,
    private _campaignService: CampaignService,
    private _global: GlobalService,
    public _router: Router,
    private _blogService: BlogService,
    private _brandsService: BrandsService,
    public _productService: ProductService,
  ) {
    // this.productService.getProducts.subscribe(response => {
    //   this.products = response.filter(item => item.type == 'fashion');
    //   this.products.filter((item) => {
    //     item.collection.filter((collection) => {
    //       const index = this.productCollections.indexOf(collection);
    //       if (index === -1) this.productCollections.push(collection);
    //     });
    //   });
    //   console.log(this.productCollections);
    // });
    if(this._global.getToken()){
      this.pageLoad = true;
    }
    this.isMember = this._global.isMember();
    this.getCampaign();
    // this.loadReview();
    this.getBrands();
    this.getBlogs();
    this.loadReview();
    this.loadProducts();
  }

  ngOnInit(): void {
  }

  getCampaign() {
    this._campaignService.list({}).subscribe((data) => {
      this.campaigns = [];
      if(Object.entries(data).length !== 0) {
        this.campaigns = data["response"]["docs"];
      }
    }, error => {
      console.log("error", JSON.stringify(error));
    });
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  getBrands() {
    let brand_item = this.local.get(this._global.SESSION_KEY + '_brands');
    if (brand_item != null && brand_item['brands']) {
      this.brands = brand_item['brands'];
    }
  }

  getBlogs() {
    let blogObj = {start: 0, rows: 5, sortOrder: 'desc', sortString: 'createdTime'};
    this._blogService.list(blogObj).subscribe(blogList => {
      if (!_.isEmpty(this._global.getVal(blogList, 'response.docs', ''))) {
        this.blogsList = blogList["response"]["docs"];
      }
    }, error => {
      console.log("error", JSON.stringify(error));
    });
  }

  loadReview() {
    let review_item = this.local.get(this._global.SESSION_KEY + '_reviews');
    if (review_item != null && review_item['reviews']) {
      this.reviews = review_item['reviews'];
    } else {
      let p = {
        'component': this._global.COMPONENTS_SEC,
        'application': 'review',
        "start": 0,
        "rows": 10,
        adminTag: "top"
      };
      this._reviewService.list(p).subscribe((reviewList) => {
        if (!_.isEmpty(this._global.getVal(reviewList, 'response.docs', ''))) {
          this.reviews = this._global.getVal(reviewList, 'response.docs', 0);
          let item = {
            reviews: this.reviews
          };
          this.local.set(this._global.SESSION_KEY + '_reviews', item, 30, 'm');
        }
      }, error => {
        console.log("error", JSON.stringify(error));
      });
    }
  }

  loadProducts() {
    this.isLoadingProduct = true;
    const p = {
      start: 0,
      rows: 16,
      adminTag: "accessories,bath,bedding",
      brand: "ROYAL LUXURY LINEN"
    };
    this._productService.product(p).subscribe((data) => {
      this.isLoadingProduct = false;
      if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
        this.products = data["response"]["docs"];
      }
    }, (error) => {
      this.isLoadingProduct = false;
    });
  }
}