import { Component, Input, OnInit } from '@angular/core';
import { CollectionSlider } from 'src/app/shared/data/slider';

@Component({
  selector: 'app-customer-reviews',
  templateUrl: './customer-reviews.component.html',
  styleUrls: ['./customer-reviews.component.scss']
})
export class CustomerReviewsComponent implements OnInit {
  @Input() reviews: any[] = [];
  public LogoSliderConfig: any = CollectionSlider;
  constructor() { }

  ngOnInit() {
    // console.log(this.reviews);
  }
}