<app-breadcrumb [title]="'Blogs'" [breadcrumb]="'Blogs'"></app-breadcrumb>
<!--section start-->
<section class="section-b-space" *ngIf="dataLoding">
    <app-royal-loading></app-royal-loading>
</section>
<section class="section-b-space blog-page" *ngIf="!dataLoding">
    <div class="container">
        <div class="row">
            <!--Blog List start-->
            <div class="col-12">
                <div class="row blog-media" *ngFor="let blog of blogsList;">
                    <div class="col-xl-6">
                        <div class="blog-left">
                            <a [routerLink]="['/blogsdetails/' + blog.id + '/' + blog.name]"><img src="assets/images/blog/blog.jpg" class="img-fluid" alt=""/></a>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="blog-right">
                            <div>
                                <h6>{{blog.createdTime | date: 'dd MMMM yyyy'}}</h6>
                                <a [routerLink]="['/blogsdetails/'+ blog.id + '/' + blog.name]"><h4>{{blog.name}}</h4></a>
                                <ul class="post-social">
                                    <li>Posted By : {{blog.contributor}} </li>
                                    <li><i class="fa fa-heart"></i>{{blog.likes}} Likes</li>
                                    <li><i class="fa fa-comments"></i> {{blog.comments}} Comments</li>
                                </ul>
                                <p [innerHTML]="blog.description | common: ['limitChar', 300,'...']"></p>
                                <a [routerLink]="['/blogsdetails/' + blog.id + '/' + blog.name]" class="btn btn-sm btn-solid mt-3">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Blog List start-->
        </div>
    </div>
</section>