import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GlobalService } from '../_common/global';
import { ApiService } from '../_services/api.service';
@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private _global: GlobalService, private api: ApiService) { }

  logout(email) {
    let params = new HttpParams();
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('application', 'member');
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('token', this._global.getToken());
    return this.api.call('member/logout', params);
  }

  view() {
    let params = new HttpParams();
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('application', 'member');
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('token', this._global.getToken());
    params = params.append('id', this._global.getSessionVal('id'));
    return this.api.call('member/view', params);
  }

  session() {
    let params = new HttpParams();
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('application', 'member');
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('token', this._global.getToken());
    return this.api.call('member/search/session', params);
  }

  updateBio(obj) {
    let params = new HttpParams();
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('token', this._global.getToken());
    params = params.append('id', this._global.getSessionVal('id'));
    params = params.append("bio", JSON.stringify(obj.bio));
    return this.api.call('member/update', params);
  }

  update(obj) {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('application', 'member');
    if (this._global.getVal(obj, 'flag', '') != "register-update") {
      delete obj["flag"];
      params = params.append('token', this._global.getToken());
      params = params.append('id', this._global.getSessionVal('id'));
    }

    let THIS = this;
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    return this.api.call('member/update', params);
  }

  updateProfileName(obj) {
    let params = new HttpParams();
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('application', 'member');
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('token', this._global.getToken());
    params = params.append('id', this._global.getSessionVal('id'));
    params = params.append("bio", JSON.stringify(obj.bio));
    return this.api.call('member/update', params);
  }
  passwordResetEmail(obj) {
    let params = new HttpParams();
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('mfsDomain', this._global.mfsDomain);
    params = params.append('captchaType', 'mfs');
    params = params.append('isMember', 'true');
    params = params.append('application', 'member');
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('token', this._global.getToken());
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    return this.api.call('reset/create', params);
  }
  resetPassword(obj) {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('application', 'member');
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    return this.api.call('reset/verify', params);
  }
  AddressList(obj) {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('token', this._global.getToken());
    params = params.append('memberId', this._global.getVal(obj, 'memberId', ''));
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('application', 'addressBook');
    params = params.append('sortString', 'updatedTime');
    params = params.append('start', '0');
    params = params.append('rows', '20');
    return this.api.call('service/search/addressBook', params);
  }
  primaryAddress(obj) {
    let params = new HttpParams();
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('token', this._global.getToken());
    params = params.append('memberId', this._global.getVal(obj, 'memberId', ''));
    params = params.append('component', this._global.COMPONENTS_SMN);
    params = params.append('application', 'addressBook');
    params = params.append('sortString', 'updatedTime');
    params = params.append('start', '0');
    params = params.append('rows', '2');
    params = params.append('isPrimary', 'true');
    return this.api.call('service/search/addressBook', params);
  }
  anonymous() {
    console.log('MemberProvider:anonymous called');
    let params = new HttpParams();
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('sess_cookie', this._global.getRandomUuid());
    params = params.append('token', this._global.getToken());
    return this.api.call('member/anonymous', params);
  }
}