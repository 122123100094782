<app-breadcrumb [title]="'Forget Password'" [breadcrumb]="'Forget Password'"></app-breadcrumb>
<!-- section start -->
<section class="pwd-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <h2>Forget Your Password</h2>
                <form [formGroup]="forgotForm" (keydown.enter)="validate()" class="theme-form">
                    <div class="row">
                        <div class="col-md-12 mb-4">
                            <input type="text" class="form-control mb-0" formControlName="email" placeholder="Enter your email">
                            <div *ngIf="submited && g.email.errors" class="error-text mt-2">
                                <div *ngIf="g.email.errors.required">This field is required.</div>
                            </div>
                        </div>
                        <div class="fp_password_captcha mb-2">
                            <img src="/cdn/captcha/{{captchaName}}.png" />
                        </div>
                        <div class="col-md-12 mb-4">
                            <input type="text" class="form-control mb-0" formControlName="captchaToken" placeholder="Enter captcha text">
                            <div *ngIf="submited && g.captchaToken.errors" class="error-text mt-2">
                                <div *ngIf="g.captchaToken.errors.required">This field is required.</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-solid" (click)="validate()">Continue</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->