import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/_common/global';
import { SignupService } from 'src/app/_services/signup.service';
import { MustMatch } from './must-match.validator';

@Component({
  selector: 'app-royal-signup',
  templateUrl: './royal-signup.component.html',
  styleUrls: ['./royal-signup.component.scss']
})
export class RoyalSignupComponent implements OnInit {
  public registerForm: FormGroup;
  public btn_process = false;
  public error: any = { "email": "", "password": "", "firstName": "", "lastName": "", "confirmPassword": "" };
  public hide = true;
  public chide = true;
  public site_name = "";
  public submitted = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _global: GlobalService,
    private _router: Router,
    private _signupService: SignupService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validators: MustMatch('password', 'confirmPassword')
  });
    this.site_name = this._global.SITE_NAME;
  }

  get f() { return this.registerForm.controls; }

  register() {
    this.error = { "email": "", "password": "", "firstName": "", "lastName": "", "confirmPassword": "" };
    this.btn_process = true;
    let r = this.registerForm.value;
    r = this._global.paramsRemoveNull(r);
    this._signupService.signup(r).subscribe(
      data => {
        if (data["status"] == 1) {
          localStorage.removeItem(this._global.SESSION_KEY);
          localStorage.setItem(this._global.SESSION_KEY, JSON.stringify(data["response"]));
          // this._router.navigate(['']);
          this.goToLogin();
        }
        else {
          this.btn_process = false;
          for (let key in data["errors"]) {
            this.error[key] = data["errors"][key]["message"];
          }
          this._global.showMessage({ type: 'error', message: this._global.getVal(data, 'message.error', '') });
        }
      },
      error => {
        console.log("Validate Error", error);
      });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    let r = this.registerForm.value;
    r = this._global.paramsRemoveNull(r);
    this.btn_process = true;
    this._signupService.signup(r).subscribe((data) => {
      this.btn_process = false;
      if (data["status"] == 1) {
        localStorage.removeItem(this._global.SESSION_KEY);
        localStorage.setItem(this._global.SESSION_KEY, JSON.stringify(data["response"]));
        // this._router.navigate(['']);
        this.goToLogin();
      }
      else {
        this.btn_process = false;
        for (let key in data["errors"]) {
          this.error[key] = data["errors"][key]["message"];
        }
        this._global.showMessage({ type: 'error', message: this._global.getVal(data, 'message.error', '') });
      }
    }, error => {
      this.btn_process = false;
      console.log("Validate Error", error);
    });
  }

  reset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  goToLogin() {
    this._router.navigate(['/login']);
  }
}
