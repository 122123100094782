import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../_common/global';
import { DomSanitizer } from '@angular/platform-browser';
import browser from 'browser-detect';
import * as R from 'ramda';
import * as _ from 'lodash';
import convertCssColorNameToHex from 'convert-css-color-name-to-hex';
const brow = browser();

@Pipe({
  name: 'common'
})
export class CommonPipe implements PipeTransform {
  constructor(public sanitizer: DomSanitizer, private _global: GlobalService) { }
  transform(value: any, args?: any): any {
    let str = "";
    if (args[0] != undefined) {
      if (args[0] == "produrl") {
        let s = [];
        if (this._global.getVal(value, 'name', '')) {
          s.push(this._global.getVal(value, 'name', '').replace(/\s/g, '-'));
        }
        /*  if (this._global.getVal(value, 'basePrice', '')) {
           s.push(this._global.getVal(value, 'basePrice', ''));
         } */
        if (this._global.getVal(value, 'brand', '')) {
          s.push(this._global.getVal(value, 'brand', '').replace(/\s/g, '-'));
        }
        /*  if (this._global.getVal(value, 'tag', '')) {
           s.push(this._global.getVal(value, 'tag', ''));
         } */
        if (this._global.getVal(value, 'category', '')) {
          let c = this._global.getVal(value, 'category', '').replace(/\s/g, '-');
          s.push(c.split("~").join(" - "));
        }
        str = '/products/details/' + this._global.getVal(value, 'id', '') + '/' + s.join(" - ");
      } else if (args[0] == "safe") {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      } else if (args[0] == "number") {
        value = parseInt(value);
        if (R.isEmpty(value) || R.identical(NaN, value)) {
          value = 0;
        }
        // console.log("rating value,",parseInt(value));
        return parseInt(value);
      } else if (args[0] == "totalproductcost") {
        return value * args[1];
      } else if (args[0] == "totalproductcost") {
        return value * args[1];
      } else if (args[0] == "productavailability") {
        let cart = false;
        let inStock = (value.inventoryPolicy == "Allow users to purchase this item when out of stock.") ? 'true' : 'false';
        let quantity = parseInt(value["quantity"]);
        //console.log(value.inventoryPolicy, inStock, quantity);
        if ((value.trackInventory == true && inStock == "true") || (value.availability == "In Stock" && quantity > 0)) {
          cart = true;
        }
        cart = true;
        return cart;
      } else if (args[0] == "cartcount") {
        let count = 0;
        if (value) {
          if (value.cart != undefined && value.cart.quantity != undefined) {
            return value.cart.quantity;
          }
        }
        return count;
      } else if (args[0] == "getval") {
        return this._global.getVal(args[1], args[2], args[3])
      } else if (args[0] == 'singleproduct') {
        let str = false;
        if (value["title"] == "Default Title") {
          str = true;
        }
        return str;
      } else if (args[0] == "sizecolor") {
        let val = value.split("/");
        let str = value;
        if (val.length > 2) {
          str = val[0] + " / " + val[1];
        }
        return str;
      } else if (args[0] == "imagereszie") {
        let img_url = value;
        Object.entries(this._global.IMAGE_TYPE).forEach(([k, v]) => {
          img_url = (img_url).replace(v + '?v=', args[1] + v + '?v=');
        });
        return img_url;
      } else if (args[0] == "colorcode") {
        return convertCssColorNameToHex(value);
      } else if (args[0] == "limitChar") {
        return String(value).substring(0, args[1]) + args[2];
      } else if (args[0] == "homereview") {
        let url = "/product/" + value['mediaId'] + "/" + value['mediaName'];
        return value['description'] + ' <a href=' + url + '> Go to product</a>';
      } else if (args[0] == "image") {
        let obj = value;
        if (obj != undefined) {
          let size = (args[4] == undefined) ? 'xs' : args[4];
          return this._global.SITE_URL + "/cdn/" + args[1] + "/" + this._global.getVal(obj, args[2], '') + "/" + size + "/" + this._global.getVal(obj, args[3], 'noname').replace(/\s/g, "").replace(/[^a-zA-Z0-9]+/g, "-") + "_" + this._global.getMilliseconds() + ".png";
        }
      }
    }
    return str;
  }
}


