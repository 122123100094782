<owl-carousel-o [options]="HomeSliderConfig" class="home-slider" [ngClass]="class">
	<ng-container *ngFor="let slider of sliders">
	  <ng-template carouselSlide>
	    <div class="home" [ngClass]="textClass" [ngStyle]="{'background-image': 'url('+slider.image+')'}">
	      <div class="container">
	        <div class="row">
	          <div class="col">
	            <div class="slider-contain">
	              <div class="slider-contain-box">
	                <h4>{{slider.title}}</h4>
	                <h1>{{slider.subTitle}}</h1>
	                <div *ngIf="slider.btnObj.length !== 0">
						<a *ngFor="let btn of slider.btnObj" [routerLink]="['/products/view', btn.link]" class="btn btn-xs btn-solid me-3">
						  {{btn.btnName}}
						</a>
					</div>
					<!-- <a class="btn btn-solid" [ngClass]="buttonClass" (click)="openWindow()">{{buttonText}}</a> -->
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </ng-template>
	</ng-container>
</owl-carousel-o>

<!-- <ng-template class="theme-modal" #sliderPopUpModel let-modal>
    <div class="modal-content">
		<div class="modal-body modal5">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close"  aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offer-content">
                                <div *ngFor="let brand of brands">
									<a href="javascript:void(0)" [routerLink]="['/products/view', {adminTag :'accessories,bath,bedding',brand:brand.name}]">
                                        {{brand.name | titlecase}}
                                    </a>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->