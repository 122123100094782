import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GlobalService } from '../_common/global';
import { ApiService } from '../_services/api.service';
@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private _global: GlobalService, private api: ApiService) { }
  list(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('status', 'active');
    params = params.append('application', 'campaign');
    params = params.append('token', this._global.getToken());
    params = params.append('component', this._global.COMPONENTS_SEC);
    return this.api.call('service/search/campaign', params);
  }
}
