
<ng-container *ngFor="let s of sharebtn|keyvalue">
    <button *ngIf="s['value']=='facebook'" [url]="url" mat-mini-fab theme="circles-dark" shareButton="facebook"
        [style.backgroundColor]="share.prop.facebook.color">
        <fa-icon [icon]="share.prop.facebook.icon" size="sm"></fa-icon>
    </button>
    <button *ngIf="s['value']=='twitter'" [url]="url" tiltle="dinesh" mat-mini-fab theme="circles-dark" shareButton="twitter" [style.backgroundColor]="share.prop.twitter.color">
        <fa-icon [icon]="share.prop.twitter.icon" size="sm"></fa-icon>
    </button>

    <button *ngIf="s['value']=='linkedin'" [url]="url" mat-mini-fab theme="circles-dark" shareButton="linkedin" [style.backgroundColor]="share.prop.linkedin.color">
        <fa-icon [icon]="share.prop.linkedin.icon" size="sm"></fa-icon>
    </button>
    <button *ngIf="s['value']=='reddit'" [url]="url" mat-mini-fab theme="circles-dark" shareButton="reddit"
        [style.backgroundColor]="share.prop.reddit.color">
        <fa-icon [icon]="share.prop.reddit.icon" size="sm"></fa-icon>
    </button>
    <button *ngIf="s['value']=='whatsapp'" [url]="url" mat-mini-fab [style.backgroundColor]="share.prop.whatsapp.color" theme="circles-dark" shareButton="whatsapp">
        <fa-icon [icon]="share.prop.whatsapp.icon" size="sm"></fa-icon>
    </button>
    <button *ngIf="s['value']=='telegram'" [url]="url" mat-mini-fab theme="circles-dark" shareButton="telegram"
        [style.backgroundColor]="share.prop.telegram.color">
        <fa-icon [icon]="share.prop.telegram.icon" size="sm"></fa-icon>
    </button>

    <button *ngIf="s['value']=='email'" mat-mini-fab theme="circles-dark" shareButton="email"
        [style.backgroundColor]="share.prop.email.color">
        <fa-icon [icon]="share.prop.email.icon" size="sm"></fa-icon>
    </button>
    <button *ngIf="s['value']=='tumblr'" mat-mini-fab theme="circles-dark" shareButton="tumblr"
        [style.backgroundColor]="share.prop.tumblr.color">
        <fa-icon [icon]="share.prop.tumblr.icon" size="sm"></fa-icon>
    </button>
    <button *ngIf="s['value']=='pinterest'" [url]="url" mat-mini-fab theme="circles-dark" shareButton="pinterest"
    [style.backgroundColor]="share.prop.pinterest.color">
    <fa-icon [icon]="share.prop.pinterest.icon" size="sm"></fa-icon>
</button>
</ng-container>