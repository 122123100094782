<app-breadcrumb [title]="'Register'" [breadcrumb]="'Register'"></app-breadcrumb>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>create account</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup]="registerForm">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label>First Name</label>
                                    <input type="text" class="form-control mb-0" placeholder="First Name" formControlName="firstName">
                                    <div *ngIf="submitted && f.firstName.errors" class="error-text mt-2">
                                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control mb-0" placeholder="Last Name" formControlName="lastName">
                                    <div *ngIf="submitted && f.lastName.errors" class="error-text mt-2">
                                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group mb-4">
                                    <label>email</label>
                                    <input type="text" class="form-control mb-0" placeholder="Email" formControlName="email">
                                    <div *ngIf="submitted && f.email.errors" class="error-text mt-2">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label>Password</label>
                                    <input type="password" class="form-control mb-0" placeholder="Enter your password" formControlName="password">
                                    <div *ngIf="submitted && f.password.errors" class="error-text mt-2">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label>Confirm Password</label>
                                    <input type="password" class="form-control mb-0" placeholder="Enter your confirm password" formControlName="confirmPassword">
                                    <div *ngIf="submitted && f.confirmPassword.errors" class="error-text mt-2">
                                        <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                        <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="create-btns">
                            <div>
                                <button class="btn btn-solid me-2" (click)="btn_process ? '' : onSubmit()">{{ btn_process ? 'processing...' : 'create Account'}}</button>
                                <button class="btn btn-outline" (click)="reset()">Cancel</button>
                            </div>
                            <button class="btn btn-solid" (click)="btn_process ? '' : goToLogin()">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->