<owl-carousel-o [options]="BlogSliderConfig" class="no-arrow">
  <ng-container *ngFor="let blog of blogs">
    <ng-template carouselSlide>
      <div class="col-md-12">
        <a [routerLink]="['/blogsdetails/'+ blog.id + '/' + blog.name]">
          <div class="classic-effect">
            <img [src]="blog.image ? blog.image : 'assets/images/blog/blog.jpg'" class="img-fluid" alt="blog">
            <span></span>
          </div>
        </a>
        <div class="blog-details">
          <h4>{{blog.createdTime | date: 'dd MMMM yyyy'}}</h4>
          <a [routerLink]="['/blogsdetails/'+ blog.id + '/' + blog.name]">
            <p>{{blog.name}}</p>
          </a>
          <hr class="style1">
          <h6> {{ 'By:' + blog.contributor}}, {{ blog.likes + 'Likes' }}</h6>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>