import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/_common/global';
import { ApiService } from 'src/app/_services/api.service';
import { MemberService } from 'src/app/_services/member.service';

@Component({
  selector: 'app-royal-forgetpassword',
  templateUrl: './royal-forgetpassword.component.html',
  styleUrls: ['./royal-forgetpassword.component.scss']
})
export class RoyalForgetpasswordComponent implements OnInit {
  public captchaName = '';
  public forgotForm: FormGroup;
  public btn_process = false;
  public error: any = { "email": "", "captchaToken": "" };
  public submited = false;
  constructor(
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private router: Router,
    private fb: FormBuilder,
    private _memberService: MemberService,
    private _global: GlobalService
  ) {
    this.initForgetPassword();
    this.captchaName = this._global.randomAlphaNumeric(8);
    console.log(this.captchaName);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['email']) {
        this.forgotForm.get("email").setValue(params['email']);
      }
    });
  }

  initForgetPassword() {
    this.forgotForm = this.fb.group({
      email: ['', Validators.required],
      captchaToken: ['', Validators.required]
    });
  }

  get g(): any {
    return this.forgotForm.controls;
  }

  validate() {
    this.btn_process = true;
    this.submited = false;
    let r = this.forgotForm.value;
    if(this.forgotForm.status === 'INVALID') {
      this.submited = true;
      return;
    }
    r = this._global.paramsRemoveNull(r);
    r['recaptcha_challenge_field']= this.captchaName,
    this._memberService.passwordResetEmail(r).subscribe((memberSession) => {
      let response = this._global.getVal(memberSession, 'response', '');
      if (!_.isEmpty(response)) {
        this.router.navigate(['']);
        this._global.showMessage({ "type": "success", "message": ' You should be receiving email ' + r["email"], "title": "" });
      } else {
        this.btn_process = false;
        for (let key in memberSession["errors"]) {
          this.error[key] = memberSession["errors"][key]["message"];
        }
        this.submited = true;
      }
    }, error => {
      console.log("Subscription error:", error);
      this._apiService.handleError(error);
    });
  }
}