import { Component, Input, OnInit } from '@angular/core';
import { BlogSlider } from 'src/app/shared/data/slider';

@Component({
  selector: 'app-home-blogs',
  templateUrl: './home-blogs.component.html',
  styleUrls: ['./home-blogs.component.scss']
})
export class HomeBlogsComponent implements OnInit {
  
  @Input() blogs: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  public BlogSliderConfig: any = BlogSlider;

}
