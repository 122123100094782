import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorageService } from 'angular-web-storage';
import { GlobalService } from 'src/app/_common/global';
import { BrandsService } from 'src/app/_services/brands.service';
import { CategoryService } from 'src/app/_services/category.service';

@Component({
  selector: 'app-royal-footer',
  templateUrl: './royal-footer.component.html',
  styleUrls: ['./royal-footer.component.scss']
})
export class RoyalFooterComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.jpg' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  public today: number = Date.now();
  public brands: any = [];

  constructor(
    public _local: LocalStorageService,
    private _global: GlobalService,
    private _brandsService: BrandsService,
    private _categoryService: CategoryService
  ) { }

  ngOnInit(): void {
    this.getBrands();
  }

  getBrands() {
    let brand_item = this._local.get(this._global.SESSION_KEY + '_brands');
    if (brand_item != null && brand_item['brands']) {
      this.brands = brand_item['brands'];
    }
  }

}