import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../_common/global';
@Injectable()
export class LoggedinGuard implements CanActivate {
  constructor(private _global: GlobalService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this._global.isMember()) {
        this.router.navigate(['']);
      }
      return true;
  }
}