import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons/faRedditAlien';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faTumblr } from "@fortawesome/free-brands-svg-icons/faTumblr";
import { faPinterestP } from "@fortawesome/free-brands-svg-icons/faPinterestP";
export const iconpack = [
    faFacebookF, faTwitter, faLinkedinIn, faRedditAlien,faTumblr,
    faTelegramPlane, faWhatsapp, faEnvelope, faPrint,faPinterestP
];