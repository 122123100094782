<!-- Home slider start-->
<section class="p-0">
  <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'fashion'" [buttonText]="'shop now'"></app-slider>
</section>
<!-- Home slider End -->

<!-- collection banner start-->
<section class="pb-0">
  <div class="container">
    <div class="row partition2">
      <div class="col-md-6" *ngFor="let collection of collections">
        <div class="collection-banner text-center mb-4" [ngClass]="collection.className">
          <img [src]="collection.image" class="img-fluid" alt="collection-banner">
          <div class="contain-banner slider-banner">
            <div class="banner-box" [ngClass]="collection.className1">
              <h4>{{collection.save}}</h4>
              <h2>{{collection.title}}</h2>
              <div class="mt-3" *ngIf="collection.btnObj.length !== 0">
                <a *ngFor="let btn of collection.btnObj" [routerLink]="['/products/view', btn.link]" class="btn btn-xs btn-solid me-3">
                  {{btn.btnName}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- collection banner End -->

<!-- Product slider start-->
<div class="title1 section-t-space">
  <h4>special offer</h4>
  <h2 class="title-inner1">top collection</h2>
</div>
<div class="container">
  <div class="row">
    <div class="col-lg-6 offset-lg-3">
      <div class="product-para">
        <p class="text-center">
          All those are top collections. Knights Bridge, Kentshire Collection, Hotel By Perthshire, Castle Hill London, Perthshire Platinum Collection.
        </p>
      </div>
    </div>
  </div>
</div>

<section class="section-b-space p-t-0">
  <div class="container">
    <div class="row">
      <div class="col" *ngIf="!isLoadingProduct">
        <owl-carousel-o class="product-m no-arrow" [options]="ProductSliderConfig">
          <ng-container *ngFor="let product of products | slice:0:16">
            <ng-template carouselSlide>
              <div class="product-box">
                <app-home-product-slide [product]="product" [currency]="_productService?.Currency" [thumbnail]="true" [cartModal]="true"></app-home-product-slide>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <div class="col" *ngIf="isLoadingProduct">
        <app-royal-loading></app-royal-loading>
      </div>
    </div>
  </div>
</section>
<!-- Product slider End -->

<!-- Parallax banner start-->
<section class="p-0">
  <div class="full-banner parallax-banner1 parallax text-center p-left"
    [ngStyle]="{'background-image': 'url(assets/images/parallax/bed.jpg)'}">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="banner-contain">
            <!-- <h2>2018</h2> -->
            <h3 class="text-white">Royal luxury trends</h3>
            <h4 class="text-white">special offer</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Parallax banner End -->

<!-- product slider -->
<!-- <section class="section-b-space">
  <div class="container">
      <div class="row multiple-slider">
          <div class="col-lg-3 col-sm-6">
              <app-product-box-vertical-slider [title]="'Bedding'"></app-product-box-vertical-slider>
          </div>
          <div class="col-lg-3 col-sm-6">
              <app-product-box-vertical-slider [title]="'Bath'"></app-product-box-vertical-slider>
          </div>
          <div class="col-lg-3 col-sm-6">
              <app-product-box-vertical-slider [title]="'Accessories'"></app-product-box-vertical-slider>
          </div>
          <div class="col-lg-3 col-sm-6">
              <app-product-box-vertical-slider [title]="'Brands'"></app-product-box-vertical-slider>
          </div>
      </div>
  </div>
</section> -->
<!-- product slider end -->

<!-- blog section start-->
<section class="blog p-t-0">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-home-blogs [blogs]="blogsList"></app-home-blogs>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!-- Reviews section start-->
<section class="section-b-space">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Customer Review</h4>
          <h2 class="title-inner1">Reviews</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" *ngIf="reviews.length !== 0">
        <app-customer-reviews [reviews]="reviews"></app-customer-reviews>
      </div>
    </div>
  </div>
</section>
<!-- Reviews section End-->

<!-- logo section start-->
<section class="section-b-space p-t-0">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Our Brands</h4>
          <h2 class="title-inner1">Brands</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" *ngIf="brands.length !== 0">
        <app-brand-logos [logos]="brands"></app-brand-logos>
      </div>
    </div>
  </div>
</section>
<!-- logo section End-->

<!-- services start-->
<div class="container">
  <section class="service border-section small-section mb-5">
    <app-services></app-services>
  </section>
</div>
<!-- services End -->

<div *ngIf="campaigns.length !== 0">
  <app-newsletter [newsLetterDetails]="campaigns"></app-newsletter>
</div>