import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-royal-socials',
  templateUrl: './royal-socials.component.html',
  styleUrls: ['./royal-socials.component.scss']
})
export class RoyalSocialsComponent implements OnInit {
  @Input('url') url: string;
  constructor() { }

  ngOnInit(): void {
  }

}