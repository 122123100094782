import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_common/global';
import { PageService } from 'src/app/_services/page.service';

@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.scss']
})
export class ReturnsComponent  implements OnInit {
  public page: any = [];
  public page_content = "";
  public dataLoding = false;
  constructor(private _pageService: PageService, private _global: GlobalService) { }

  ngOnInit() {
    this.dataLoding = true;
    this._pageService.list({ name: 'new_returns' }).subscribe(pageData => {
      this.dataLoding = false;
      if (this._global.getVal(pageData, 'response.numFound', 0) > 0) {
        this.page = this._global.getVal(pageData, 'response.docs', {});
        this.page_content = this.page[0]["metaData"];
      }
    }, error => {
      this.dataLoding = false;
      console.log("error", JSON.stringify(error));
    });
  }
}
