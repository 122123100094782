import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_common/global';
import { LineItem } from 'src/app/_models/lineItem.model';
import { ShopifyService } from 'src/app/_services/shopify.service';

@Component({
  selector: 'app-cart-royal',
  templateUrl: './cart-royal.component.html',
  styleUrls: ['./cart-royal.component.scss']
})
export class CartRoyalComponent implements OnInit {
  cartId: string;
  cartOpen: boolean;
  cart_summary: any = [];
  lineItems: LineItem[];
  constructor(
    public _global: GlobalService,
    private _shopifyService: ShopifyService) {
      this._global.CARTSUMMARY = this._global.cartItems();
      console.log(this._global.CARTSUMMARY);
    }

  ngOnInit() {
  }

  remove(varientId) {
    if (this._global.CARTSUMMARY.length) {
      let item_list = [];
      let cart = localStorage.getItem(this._global.CART_STORAGE);
      if (cart !== undefined && cart !== null) {
        let cart_list = JSON.parse(cart);
        Object.entries(cart_list).forEach(([key, value]) => {
          if (value["variantId"] != varientId) {
            item_list.push(value);
          }

        });
      }

      localStorage.setItem(this._global.CART_STORAGE, JSON.stringify(item_list));
      this._global.CARTSUMMARY = this._global.cartItems();
      this._global.showMessage({ "type": "success", "message": "Product has been removed from your cart", "title": "" });
    }
  }
  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  increaseQuantity(variants, qty, pid,pname) {
    if (qty >= 1) {
      const lineItem = new LineItem(variants, qty + 1, pid,pname);
      this._global.addItemToCart(lineItem);
    }
  }

  decreaseQuantity(variants, qty, pid,pname) {
    if (qty > 1) {
      const lineItem = new LineItem(variants, qty - 1, pid,pname);
      this._global.addItemToCart(lineItem);
    }
  }

  get total(): number {
    if (this._global.CARTSUMMARY.length) return this._global.CARTSUMMARY.map(lineItem => lineItem.quantity * (+lineItem.variant.price.amount)).reduce((prev, next) => prev + next);
    else return 0;
  }

  clear() {
    localStorage.setItem(this._global.CART_STORAGE, JSON.stringify([]));
    this._global.CARTSUMMARY = this._global.cartItems();
  }

  checkout() {
    let cart = localStorage.getItem(this._global.CART_STORAGE);
    let lineItems = JSON.parse(cart);
    this._shopifyService.createCheckout(lineItems).then(({ model, data }) => {
      if (!data.checkoutCreate.userErrors.length) {
        this.openCheckout(data.checkoutCreate.checkout);
        let i = 0;
        data.checkoutCreate.checkout.lineItems.edges.forEach(edge => {
          i++;
        });
      } else {
        data.checkoutCreate.userErrors.forEach(error => {
          alert(JSON.stringify(error));
        });
      }
    }, err => {
      alert(err)
    });
  }

  openCheckout(checkout) {
    window.open((checkout.webUrl).replace('www.royalluxurylinen.com',this._global.CHEKOUT_URL)+this._global.getCheckoutCustomerDetails(),'_self');
  }
  
}