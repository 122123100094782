import { Injectable } from '@angular/core';
import { GlobalService } from '../_common/global';
import { ApiService } from '../_services/api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SocialService {
  constructor(private _global: GlobalService, private api: ApiService) { }
  like(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('sess_cookie', this._global.getToken());
    params = params.append('token', this._global.getToken());
    return this.api.call('service/like/' + obj["application"], params);
  }

  dislike(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('sess_cookie', this._global.getToken());
    return this.api.call('service/dislike/' + obj["application"], params);
  }

  favorite(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });

    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('sess_cookie', this._global.getToken());
    return this.api.call('service/favorite/' + obj["application"], params);
  }

  flag(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('sess_cookie', this._global.getToken());
    return this.api.call('service/flag/' + obj["application"], params);
  }

  view(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('sess_cookie', this._global.getToken());
    return this.api.call('service/view/' + obj["application"], params);
  }

  check(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('sess_cookie', this._global.getToken());
    return this.api.call('service/check/' + obj["application"], params);
  }
  rating(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('sess_cookie', this._global.getToken());
    return this.api.call('service/rate/' + obj["application"], params);
  }
}