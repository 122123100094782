import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalService } from '../_common/global';
import * as _ from 'lodash';
import { Router } from '@angular/router'
import { Observable, Subscription, map } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private router: Router, private _global: GlobalService, private http: HttpClient) {
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
  };

  public call(url, params) {
    //  console.log("Calling api with obj", params);
    let token = this._global.getToken();
    if (_.isEmpty(token)) {
      Object.entries(params["updates"]).forEach(([key, value]) => {
        if (value["param"] == "token") {
          token = value["value"];
        }
      });
    }

    //   console.error('Token:', token);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'max-age': '0',
        'must-revalidate': 'true',
        'x-forwarded-for': '1',
        's-maxage': '0',
        'token': "" + token + ""
      })
    };

    // console.log("making call to ", url);
    //  console.log("making call with httpOptions ", JSON.stringify(httpOptions));
    return this.http.post('/api/' + url, params, httpOptions).pipe(map(data => {
      // console.log("ApiProvider:call response received", data);
      //alert("no"+this._global.getVal(data, 'status', ''))
      if (this._global.getVal(data, 'status', '') == '407') {
        localStorage.removeItem(this._global.SESSION_KEY);
        this.router.navigate(['/']);
        return {};
      } else if (this._global.getVal(data, 'status', '') == '401') {

        // console.log("status=407---->", data);
        /* localStorage.removeItem(this._global.SESSION_KEY);
        this.router.navigate(['/']); */
        return {};
      } else if (this._global.getVal(data, 'status', '') == '200') {
        // console.log("status=200---->", data);
        return data;
      }
      else if (this._global.getVal(data, 'response', '') == '') {
        //  console.log("status=OTHER1---->", data);
        return {};
      } else {
        // console.log("status=OTHER2---->", data);
        return data;
      }
    }));
  }

  public getMemberSession() {
    // console.log('Inside getMemberSession');
    if (this._global.isLocalStorage) {
      // console.log('Inside getMemberSession: 1');
      let session = this._global.getSession();
      if (session != null) {
        //console.log('Inside getMemberSession: session=', session);
        var isMember = session[this._global.SESSION_KEY]['isMember'];
        var isAnonymousUser = session[this._global.SESSION_KEY]['anonymousUser'];
        var isLocalStorage;
        //console.log('Inside getMemberSession: isMember=', isMember);
        // console.log('Inside getMemberSession: isAnonymousUer=', isAnonymousUser);
        return new Observable<{}>(observer => {
          const subscription = new Subscription();
          return subscription;
        }
        );
      }
      else {
        //  console.log('Inside getMemberSession: 2');
        let params = new HttpParams();
        params = params.append('domainName', this._global.DOMAIN_NAME);
        params = params.append('sess_cookie', this._global.getRandomUuid());
        params = params.append('token', this._global.getToken());
        //  console.log('Inside getMemberSession: params', params);
        return this.call('member/anonymous', params);
      }
    }
  }

  /* logout(){
    this._memberService.logout({}).subscribe(
      data => {
        localStorage.removeItem(this._global.SESSION_KEY);

        this.getMemberSession().subscribe(
          memberSession => {
            let response = this._global.getVal(memberSession, 'response', '');
            if (response != null && response != undefined && (response != '' || response != {})) {
              localStorage.removeItem(this._global.SESSION_KEY);
              localStorage.setItem(this._global.SESSION_KEY, JSON.stringify(response));
              this.router.navigate(['']);
            }
            else {
              console.log("ERROR : memberSession :", memberSession);
            }
          },
          error => {
            console.log("Subscription error:", error);
            this.handleError(error);
          }
        );

      },
      error => {
        // localStorage.removeItem(this._global.SESSION_KEY);
        console.log("error", JSON.stringify(error));
        this.router.navigate(['']);
      }); 
  } */
}