<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-header">
            <h5 class="mb-0">{{ product?.name | titlecase }}</h5>
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6  col-xs-12">
                    <div class="quick-view-img">
                        <img [src]="product.pathToMedia.src ? product.pathToMedia.src : 'assets/images/product/placeholder.jpg'" [alt]="product.title" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                    <div class="product-right">
                        <h2>{{ product?.name | titlecase }}</h2>
                        <h3>
                            {{ product?.discountPrice | currency: product?.currency:'symbol' }}
                            <del *ngIf="product?.basePrice"><span class="money">{{ product?.basePrice | currency: product?.currency:'symbol' }}</span></del>
                        </h3>
                        <!-- <ul class="color-variant" *ngIf="Color(product.variants).length">
                            <li [class]="color" *ngFor="let color of Color(product.variants)"
                                [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, product)">
                            </li>
                        </ul> -->
                        <div class="border-product">
                            <h6 class="product-title">product details</h6>
                            <p [innerHTML]="product?.description | common: ['limitChar', 100,'...']"></p>
                        </div>
                        <div class="product-description border-product">
                            <!-- <div class="size-box" *ngIf="Size(product.variants).length">
                                <ul>
                                    <li *ngFor="let size of Size(product.variants)">
                                        <a href="javascript:void(0)">{{ size | titlecase }}</a>
                                    </li>
                                </ul>
                            </div> -->
                            <h5 class="avalibility" *ngIf="counter <= product?.stock"><span>In Stock</span></h5>
                            <h5 class="avalibility" *ngIf="counter > product?.stock"><span>Out of Stock</span></h5>
                            <!-- <h6 class="product-title">quantity</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div> -->
                        </div>
                        <div class="product-buttons">
                            <!-- <a href="javascript:void(0)" class="btn btn-solid">add to cart</a> -->
                            <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]" class="btn btn-solid">view detail</a> -->
                            <a class="btn btn-solid" [routerLink]="['/products/details', {productId: product.id}]">view detail</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>