import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";
import { GlobalService } from 'src/app/_common/global';
import { ShopifyService } from 'src/app/_services/shopify.service';

@Component({
  selector: 'app-cart-variation',
  templateUrl: './cart-variation.component.html',
  styleUrls: ['./cart-variation.component.scss']
})
export class CartVariationComponent implements OnInit, OnDestroy {

  @Input() direction: string = 'right'; // Default Direction Right

  public products: Product[] = [];

  constructor(public _global: GlobalService, private _shopifyService: ShopifyService, public productService: ProductService) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {
    this.productService.OpenCart = false;
  }

  closeCart() {
    this.productService.OpenCart = false;
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  checkout() {
    let cart = localStorage.getItem(this._global.CART_STORAGE);
    let lineItems = JSON.parse(cart);
    this._shopifyService.createCheckout(lineItems).then(({ model, data }) => {
      if (!data.checkoutCreate.userErrors.length) {
        this.openCheckout(data.checkoutCreate.checkout);
        let i = 0;
        data.checkoutCreate.checkout.lineItems.edges.forEach(edge => {
          i++;
        });
      } else {
        data.checkoutCreate.userErrors.forEach(error => {
          alert(JSON.stringify(error));
        });
      }
    }, err => {
      alert(err)
    });
  }

  openCheckout(checkout) {
    window.open((checkout.webUrl).replace('www.royalluxurylinen.com',this._global.CHEKOUT_URL)+this._global.getCheckoutCustomerDetails(),'_self');
  }

  ngOnDestroy(): void {
    this.closeCart();
  }

}
