import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GlobalService } from '../_common/global';
import { ApiService } from '../_services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  Currency: any;
  addToWishlist(product: any) {
    throw new Error('Method not implemented.');
  }
  addToCompare(product: any) {
    throw new Error('Method not implemented.');
  }

  constructor(private _global: GlobalService, private api: ApiService) { }
  call(url, params) {
    console.log("api params", params);
  }
  product(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('application', 'product');
    params = params.append('token', this._global.getToken());
    params = params.append('component', this._global.COMPONENTS_SEC);
    return this.api.call('service/search/product', params);
  }
  related(obj) {
    let params = new HttpParams();
    params = params.append('id', "("+obj["id"]+")");
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('application', 'product');
    params = params.append('token', this._global.getToken());
    params = params.append('component', this._global.COMPONENTS_SEC);
    return this.api.call('service/search/product', params);
  }
  suggest(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('application', 'product');
    params = params.append('token', this._global.getToken());
    params = params.append('component', this._global.COMPONENTS_SEC);
    return this.api.call('service/suggest/product', params);
  }
  view(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    params = params.append('component', this._global.COMPONENTS_SEC);
    params = params.append('application', 'product');
    return this.api.call('product/view', params);
  }
  addToCart(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
   // params = params.append('cartId',"d4e8ae31-eb5e-4e88-bbf1-9f8390f6b642");
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('application', 'product');
    params = params.append('token', this._global.getToken());
    params = params.append('component', this._global.COMPONENTS_SEC);
    return this.api.call('cart/add', params);
  }

  addToWishList(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
   // params = params.append('cartId',"d4e8ae31-eb5e-4e88-bbf1-9f8390f6b642");
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('application', 'wishlist');
    params = params.append('token', this._global.getToken());
    params = params.append('component', this._global.COMPONENTS_SEC);
    return this.api.call('wishlist/add', params);
  }

  cartSummary(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('application', 'product');
    params = params.append('token', this._global.getToken());
    params = params.append('component', this._global.COMPONENTS_SEC);
    return this.api.call('cart/view', params);
  }
  wishlistAdd(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
        params = params.append(key, v);
    });
    params = params.append('component', this._global.COMPONENTS_SEC);
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('sess_cookie', this._global.getToken());
    params = params.append('token', this._global.getToken());
    return this.api.call(obj["application"]+"/add", params);
  }
}