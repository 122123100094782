import { Component, Input, OnInit } from '@angular/core';
import { LogoSlider } from 'src/app/shared/data/slider';

@Component({
  selector: 'app-brand-logos',
  templateUrl: './brand-logos.component.html',
  styleUrls: ['./brand-logos.component.scss']
})
export class BrandLogosComponent implements OnInit {
  @Input() logos: any[] = [];
  public LogoSliderConfig: any = LogoSlider;
  constructor() { }

  ngOnInit() {
  }
}