import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ElementsComponent } from './elements/elements.component';
import { RoyalComponent } from './royal/royal.component';
import { AboutComponent } from './royal/about/about.component';
import { RoyalBlogComponent } from './royal/blog/blog.component';
import { BlogsDetailsComponent } from './royal/blog/blogs-details/blogs-details.component';
import { CartRoyalComponent } from './royal/cart-royal/cart-royal.component';
import { ContactUsComponent } from './royal/contact-us/contact-us.component';
import { HelpComponent } from './royal/help/help.component';
import { HomeComponent } from './royal/home/home.component';
import { PrivacyAndSecurityComponent } from './royal/privacy-and-security/privacy-and-security.component';
import { ProductDetailsViewComponent } from './royal/products/product-views/product-list-view/product-details-view/product-details-view.component';
import { ProductViewsComponent } from './royal/products/product-views/product-views.component';
import { ProductsComponent } from './royal/products/products.component';
import { ReturnsComponent } from './royal/returns/returns.component';
import { RoyalForgetpasswordComponent } from './royal/royal-forgetpassword/royal-forgetpassword.component';
import { RoyalLoginComponent } from './royal/royal-login/royal-login.component';
import { RoyalSignupComponent } from './royal/royal-signup/royal-signup.component';
import { TermsOfUserComponent } from './royal/terms-of-user/terms-of-user.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // {
  //   path: 'royal',
  //   component: RoyalComponent,
  //   loadChildren: () => import('./royal/royal.module').then(m => m.RoyalModule)
  // },
  // { 
  //   path: 'elements', 
  //   component: ElementsComponent,
  //   loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) 
  // },
  // {
  //   path: 'main',
  //   component: RoyalComponent
  // },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'aboutUs',
    component: AboutComponent
  },
  {
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'products/view/:id',
    component: ProductViewsComponent
  },
  {
    path: 'products/details',
    component: ProductDetailsViewComponent
  },
  {
    path: 'products/view',
    component: ProductViewsComponent
  },
  {
    path: 'blogs',
    component: RoyalBlogComponent
  },
  {
    path: 'blogsdetails/:id/:name',
    component: BlogsDetailsComponent
  },
  {
    path: 'contactUs',
    component: ContactUsComponent
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'cart',
    component: CartRoyalComponent
  },
  {
    path: 'returns',
    component: ReturnsComponent
  },
  {
    path: 'terms',
    component: TermsOfUserComponent
  },
  {
    path: 'privacy',
    component: PrivacyAndSecurityComponent
  }, {
    path: 'login',
    component: RoyalLoginComponent
  }, {
    path: 'signup',
    component: RoyalSignupComponent
  }, {
    path: 'forgot-password',
    component: RoyalForgetpasswordComponent
  },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
