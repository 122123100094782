import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/_common/global';
import { CommonPipe } from 'src/app/_pipe/common.pipe';
import * as _ from 'lodash';
import { ProductService } from 'src/app/_services/product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  isMember = false;
  cart_summary: any = [];
  products: any = [];
  request: any = [];
  adminTagImage: any = [];
  viewType = "grid";
  public viewCol: number = 25;
  pageno = 1;
  perPage = 12;
  currentPage = 0;
  sorts: any;
  start = 0;
  totalRecord = 0;
  product_load = false;
  data: any = [];
  showMore = true;
  users = [];
  schema: any = [];
  totalItem: Number = 0;
  selectedproduct: any;
  loading: false;
  query_params: any = [];
  facets_data: any = [];
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  page_loading = true;
  dataLoding = true;
  params: any = [];
  pageCounter = 1;
  //public sortings = { "name|asc": "Alphabetically,A-Z", "name|desc": "Alphabetically,Z-A", "basePrice|asc": "Price,Low to High", "basePrice|desc": "Price,High to Low" };
  public sortings = { "basePrice|asc": "Price,Low to High", "basePrice|desc": "Price,High to Low" };
  constructor(
    private _commonPipe: CommonPipe,
    private _productService: ProductService,
    private _global: GlobalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.isMember = this._global.isMember();
    this.adminTagImage = this._global.tagImageList();
    this.isTablet = this._global.isTablet;
    this.isMobile = this._global.isMobile;
    this.isDesktop = this._global.isDesktop;
    this.sorts = "Sort";
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.query_params = params;
      let p = {
        start: 0,
        rows: this.perPage
      }
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          p[key] = value;
        }
        if (key == "start" && params["start"]) {
          p.start = (params["start"] - 1) * this.perPage;
        }
      });
      this.currentPage = params["start"];
      this.request = p;
      this.loadAppData(p);
    });
  }

  loadAppData(p) {
    this.totalRecord = 0;
    this.products = [];
    this.dataLoding = true;
    this._productService.product(p).subscribe(
      data => {
        console.log(data);
        this.dataLoding = false;
        if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
          this.params = this._global.getVal(data, 'request', '');
          this.products = data["response"]["docs"];
          this.totalRecord = this._global.getVal(data, 'response.numFound', '0');
          let list = [];
          Object.entries(this.products).forEach(([key, value]) => {
            let l = {
              "@type": "ListItem",
              "position": parseInt(key) + 1,
              "url": this._global.SITE_URL + this._commonPipe.transform(value, ['produrl']),
              "name": value["name"],
            }
            list.push(l);
          });

          this.schema = {
            '@context': 'http://schema.org',
            '@type': 'ItemList',
            'itemListElement': list
          };

        }
      },
      error => {
        this.dataLoding = true;
        console.log("error", JSON.stringify(error));
      });
  }

  onScrollDown() {
    if (!this.dataLoding) {
      this.dataLoding = true;
      if (this.totalRecord <= this.pageCounter * this.perPage) {
        this.dataLoding = false;
      } else {
        let p = this._global.generateParams(this.params, ['component', 'start', 'language', 'url', 'dialect']);
        p['start'] = this.pageCounter * this.perPage;
        this._productService.product(p).subscribe(
          data => {
            console.log(data);
            if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
              this.products = this.products.concat(data["response"]["docs"]);
              let list = [];
              Object.entries(this.products).forEach(([key, value]) => {
                let l = {
                  "@type": "ListItem",
                  "position": parseInt(key) + 1,
                  "url": this._global.SITE_URL + this._commonPipe.transform(value, ['produrl']),
                  "name": value["name"],
                }
                list.push(l);
              });

              this.schema = {
                '@context': 'http://schema.org',
                '@type': 'ItemList',
                'itemListElement': list
              };

              this.dataLoding = false;
              this.pageCounter += 1;
              this.params = this._global.getVal(data, 'request', '');
            } else {
              this.dataLoding = false;
            }
          },
          error => {
            console.log("error", JSON.stringify(error));
          });

      }
    }
  }

  gtagEvent(product) {
    // this._global.gtagClick('view_item', [{ 'url': this._global.getCurrentUrl() + '/product/' + product.id, 'name': product.name }]);
  }
  public openProductDialog(product) {

    // let dialogRef = this.dialog.open(ProductDialogViewComponent, {
    //   data: product,
    //   panelClass: 'product-dialog'
    // });
    /* dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(['/product/', product.id]);
      }
    }); */

  }

  public changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
  changeSorting(value) {
    this.sorts = this._global.getVal(this.sortings, value, 'Sort');
    let sort = value.split("|");
    let p = {
      start: 0,
      rows: this.perPage,
      sortString: sort[0],
      sortOrder: sort[1]
    };
    Object.entries(this.query_params).forEach(([key, value]) => {
      if (value) {
        p[key] = value;
      }
    });
    this.loadAppData(p);

  }

  public addToWishList(product) {
    let p = {
      application: 'wishlist',
      quantity: 1,
      productId: product['id']
    };
    this._productService.wishlistAdd(p).subscribe(
      data => {
        let message = '';
        if (data["status"] == "1") {
          message = this._global.getVal(data, 'message.info', "");
          this._global.showMessage({ "type": 'success', "message": message });
        } else {
          this._global.showMessage({ "type": 'error', "message": this._global.getVal(data, 'message.error', '') });
        }
      },
      error => {
        console.log("error", JSON.stringify(error));
      });
  }

  public getBgImage(image) {
    let bgImage = {
      'background-image': "url(" + image + ")"
    };
    return bgImage;
  }

}