import { Variant } from './variant.model';

export class LineItem {
    /*id: string = '';
    title: string = '';
    quantity: number = 0;
    variant: Variant = new Variant;
    imageSrc: string;
    price: number;

    constructor(title, variant, price, imageSrc, quantity) {
        this.title = title;
        this.variant = variant;
        this.price = price;
        this.imageSrc = imageSrc;
        this.quantity = quantity;
    }*/
    variant: Variant;
    quantity: number;
    // id?: string;
    variantId: string;//for shopify API
    id: string;
    pid: string; //product Id
    pname: string; //product Id
    constructor(
        variant: Variant,
        quantity: number,
        pid:string,
        pname:string
    ) {
        this.variant = variant;
        this.quantity = quantity;
        this.variantId = variant.id;
        this.pid = pid;
        this.pname = pname;
    }
}
