<owl-carousel-o [options]="LogoSliderConfig" class="no-arrow">
    <ng-container *ngFor="let review of reviews">
        <ng-template carouselSlide>
            <div>
                <!-- <div class="logo-block text-center">
                    <a [routerLink]="['/products/view', {adminTag :'accessories,bath,bedding',brand:logo.name}]">
                        <img alt="{{logo.name}}" src="{{ logo|common:['image','brand','id','name','m'] }}" onerror="this.src='assets/images/logos/default_logo.png';" class="w-auto">
                        <div class="logo-name">
                            {{logo.name | titlecase}}
                        </div>
                    </a>
                </div> -->
                <div class="media customer-review">
                    <img src="assets/images/review/default_user.jpg" alt="review.contributor">
                    <div class="media-body">
                        <h4>{{review.contributor | translate}}</h4>
                        <p class="mb-0"><span>{{review.createdTime | date: 'dd MMMM yyyy'}}</span></p>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                        <p class="mb-0" [innerHTML]="review.description | common: ['limitChar', 60,'...']"></p>
                        <p> 
                            <a href="javascript:void(0)" class="anchor-tag" [routerLink]="['/products/details', {productId: review.mediaId}]">Go to Products</a>
                        </p>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>