import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import * as _ from 'lodash';
import { GlobalService } from 'src/app/_common/global';
import { ApiService } from 'src/app/_services/api.service';
import { BrandsService } from 'src/app/_services/brands.service';
import { CategoryService } from 'src/app/_services/category.service';
import { MemberService } from 'src/app/_services/member.service';
import { ProductService } from 'src/app/_services/product.service';
import { ShopifyService } from 'src/app/_services/shopify.service';

@Component({
  selector: 'app-royal-header',
  templateUrl: './royal-header.component.html',
  styleUrls: ['./royal-header.component.scss']
})
export class RoyalHeaderComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.jpg'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  public stick: boolean = false;
  public brands: any = [];
  public isMember = false;
  public member_img: any = [];
  public isAdmin = false;

  constructor(
    public local: LocalStorageService,
    public session: SessionStorageService,
    private _apiService: ApiService,
    private _brandsService: BrandsService,
    private _categoryService: CategoryService,
    private _shopifyService: ShopifyService,
    private _productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    public _global: GlobalService,
    private _memberService: MemberService
  ) {
    
    this._global.CARTSUMMARY = this._global.cartItems();
    this.branda();
    this.menuList();
    this.loginCheck();
  }

  ngOnInit(): void {
    this._global.loggingUserCheck.subscribe((data) => {
      if(data) {
        this.loginCheck();
      }
    });
    this._categoryService.allCategoryType().subscribe((response: any) => {
      // console.log(response);
    });
  }

  menuList() {
    let brand_item = this.local.get(this._global.SESSION_KEY + '_brands');
    if (brand_item != null && brand_item['brands']) {
      this.brands = brand_item['brands'];
    }
  }

  branda() {
    let brand_item = this.local.get(this._global.SESSION_KEY + '_brands');
    if (brand_item != null && brand_item['brands']) {
      this.brands = brand_item['brands'];
    }
  }

  loginCheck() {
    this.isMember = this._global.isMember();
    this.member_img.id=this._global.getSessionVal("id");
    this.member_img.name=this._global.getSessionVal("name");
    let role_name = this._global.getVal( this._global.userSiteDetails(), 'roleName', '');
    if (role_name === "Site Admin" || role_name === "Org Admin") {
      this.isAdmin=true;
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  logout() {
    this._memberService.logout({}).subscribe(data => {
      localStorage.removeItem(this._global.SESSION_KEY);
      this._apiService.getMemberSession().subscribe( memberSession => {
          let response = this._global.getVal(memberSession, 'response', '');
          if (response != null && response != undefined && (response != '' || Object.assign(response).length !== 0)) {
            localStorage.removeItem(this._global.SESSION_KEY);
            localStorage.setItem(this._global.SESSION_KEY, JSON.stringify(response));
            this.router.navigate(['']);
          }
          else {
            console.log("ERROR : memberSession :", memberSession);
          }
          this.loginCheck();
          this._global.getLogedInUser(false);
        }, error => {
          console.log("Subscription error:", error);
          this._apiService.handleError(error);
        }
      );
    }, error => {
      // localStorage.removeItem(this._global.SESSION_KEY);
      console.log("error", JSON.stringify(error));
      this.router.navigate(['']);
    });
  }

}