import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/_common/global';
import { LoginService } from 'src/app/_services/login.service';

@Component({
  selector: 'app-royal-login',
  templateUrl: './royal-login.component.html',
  styleUrls: ['./royal-login.component.scss']
})
export class RoyalLoginComponent implements OnInit {

  public loginForm: FormGroup;
  public returnUrl = "/";
  public navigateByUrl = false;
  public submitted = false;
  public btn_process = false;
  public error: any = { "email": "", "password": "", "error": "" };

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _loginService: LoginService,
    private _global: GlobalService,
    private _router: Router
  ) {
    
    this.route.queryParams.subscribe(params => {
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          if (key == "returnUrl") {
            this.returnUrl = value;
          }
          if (key == "navigateByUrl") {
            this.navigateByUrl = value;
          }
        }
      });
    });
  }
  
  ngOnInit(): void {
    this.initLogin();
  }

  goToSignUp() {
    this._router.navigate(['/signup']);
  }

  initLogin() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  postLogin(r) {
    this.btn_process = true;
    this._loginService.login(r).subscribe(data => {
      if (data["status"] == 1) {
        localStorage.removeItem(this._global.SESSION_KEY);
        localStorage.setItem(this._global.SESSION_KEY, JSON.stringify(data["response"]));
        if (this.navigateByUrl) {
          this._router.navigateByUrl(this.returnUrl);
        } else {
          this._router.navigate([this.returnUrl]);
        }
      } else if (data["status"] == "-2") {
        this.btn_process = false;
        this.error['error'] = this._global.getVal(data, 'message.error', '');
        this._global.showMessage({ severity: 'warning', message: this._global.getVal(data, 'message.error', '') });
      }
      else {
        this.btn_process = false;
        for (let key in data["errors"]) {
          this.error[key] = data["errors"][key]["message"];
        }
      }
      this._global.getLogedInUser(true);
    }, error => {
      console.log("Validate Error", error);
    });
  }

  loginRoyal() {
    this.submitted = false;
    if(this.loginForm.status === 'INVALID') {
      this.submitted = true;
      return true;
    }
    this.postLogin(this.loginForm.value);
  }

  reset() {
    this.submitted = false;
    this.loginForm.reset();
  }
}
