<app-breadcrumb [title]="'Help'" [breadcrumb]="'Help'"></app-breadcrumb>
<section class="section-b-space" *ngIf="dataLoding">
    <app-royal-loading></app-royal-loading>
</section>
<!-- about section start -->
<section class="about-page section-b-space" *ngIf="!dataLoding">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="banner-section">
                    <img  src="assets/images/help.jpg" class="img-fluid" alt="about-us"/>
                </div>
            </div>
            <div class="col-sm-12">
                <div [innerHTML]="page_content"></div>
            </div>
        </div>
    </div>
</section>
<!-- about section end -->
<!-- service section -->
<div class="container" *ngIf="!dataLoding">
    <section class="service border-section small-section mb-5">
      <app-services></app-services>
    </section>
  </div>
<!-- service section end -->