import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-product-slide',
  templateUrl: './home-product-slide.component.html',
  styleUrls: ['./home-product-slide.component.scss']
})
export class HomeProductSlideComponent {
  public loader = false;
  @Input() product: any;
}
