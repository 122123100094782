import { Injectable } from '@angular/core';
import { GlobalService } from '../_common/global';
import { ApiService } from '../_services/api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(private _global: GlobalService, private api: ApiService) { }
  create(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('application', 'review');
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());

    return this.api.call('review/create/' + obj["mediaType"], params);

  }
  list(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    return this.api.call('service/search/review', params);
  }

  open(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('token', this._global.getToken());
    return this.api.call('service/search/review', params);
  }
}