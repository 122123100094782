import { Component, Input, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ShareService } from '@ngx-share/core';
import { iconpack } from 'src/icons';

@Component({
  selector: 'app-share-social-links',
  templateUrl: './share-social-links.component.html',
  styleUrls: ['./share-social-links.component.scss']
})
export class ShareSocialLinksComponent implements OnInit {

  @Input('sharebtn') sharebtn: object;
  @Input('url') url: string;
  constructor(library: FaIconLibrary, public share: ShareService, ) { 
    library.addIcons(...iconpack);
  }

  ngOnInit(): void {
  }

}
