<owl-carousel-o [options]="LogoSliderConfig" class="no-arrow">
    <ng-container *ngFor="let logo of logos">
        <ng-template carouselSlide>
            <div>
                <div class="logo-block text-center">
                    <a href="javascript:void(0)" [routerLink]="['/products/view', {adminTag :'accessories,bath,bedding',brand:logo.name}]">
                        <img alt="{{logo.name}}" src="{{ logo|common:['image','brand','id','name','m'] }}" onerror="this.src='assets/images/logos/default_logo.png';" class="w-auto">
                        <div class="logo-name">
                            {{logo.name | titlecase}}
                        </div>
                    </a>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>