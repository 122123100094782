import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as _ from 'lodash';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './_services/api.service';
import { GlobalService } from './_common/global';
import { BrandsService } from './_services/brands.service';
import { LocalStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public pageLoad = false;
  public brands: any = [];
  // For Progressbar
  public loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor (
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    public local: LocalStorageService,
    private _brandsService: BrandsService,
    translate: TranslateService,
    private _apiService: ApiService,
    public _global: GlobalService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }

    if(this._global.getToken()){
      this.pageLoad = true;
    }

    this._apiService.getMemberSession().subscribe((memberSession: any) => {
      let response = this._global.getVal(memberSession, 'response', '');
      if (response !== null && response != undefined && (response !== '' || Object.entries(response).length !== 0)) {
        localStorage.removeItem(this._global.SESSION_KEY);
        localStorage.setItem(this._global.SESSION_KEY, JSON.stringify(response));
        this.pageLoad = true;
      } else {
        console.log("ERROR : memberSession :", memberSession);
      }
    }, error => {
      console.log("Subscription error:", error);
      this._apiService.handleError(error);
    });
  }

  ngOnInit(): void {
    this.brand();
  }

  brand() {
    this._brandsService.brand({}).subscribe((data) => {
      if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
        this.brands = data["response"]["docs"];
        let item = {
          brands: this.brands
        };
        this.local.set(this._global.SESSION_KEY + '_brands', item, 60, 'm');
      }
    }, (error) => {
      console.log("error", JSON.stringify(error));
    });
  }

}
