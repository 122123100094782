import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from 'src/app/_common/global';
import { PageService } from 'src/app/_services/page.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  public page: any = [];
  public page_content = "";
  public dataLoding = false;

  constructor(private sanitizer: DomSanitizer,private _pageService: PageService, private _global: GlobalService) { }

  ngOnInit() {
    this.dataLoding = true;
    this._pageService.list({ name: 'about_us' }).subscribe((pageData) => {
      this.dataLoding = false;
      if (this._global.getVal(pageData, 'response.numFound', 0) > 0) {
        this.page = this._global.getVal(pageData, 'response.docs', {});
        this.page_content = this.page[0]["metaData"];
      }
    }, error => {
      this.dataLoding = false;
      console.log("error", JSON.stringify(error));
    });
  }
  makeSanitize(str: any) {
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }
}
