import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import * as _ from 'lodash';
import { GlobalService } from 'src/app/_common/global';
import { BlogService } from 'src/app/_services/blog.service';
import { CommentService } from 'src/app/_services/comment.service';
import { SocialService } from 'src/app/_services/social.service';

@Component({
  selector: 'app-blogs-details',
  templateUrl: './blogs-details.component.html',
  styleUrls: ['./blogs-details.component.scss']
})
export class BlogsDetailsComponent implements OnInit {
  public blogId;
  public blogDetails: any = [];
  public isMember = false;
  public comment: any = { "description": "" };
  public error: any = { "description": "" };
  public comments: any = [];
  public comment_btn = false;
  public isLoading = false;

  constructor(
    private router: Router,
    private _comment: CommentService,
    private _socialService: SocialService,
    private route: ActivatedRoute,
    private _blogService: BlogService,
    private _global: GlobalService
  ) {
    this.isMember = this._global.isMember();
    this.route.paramMap.subscribe(params => {
      this.blogId = params.get('id');
    });
    
  }

  ngOnInit() {
    if(this.blogId) {
      this.blogsDetails(this.blogId);
    }
  }

  blogsDetails(blogId) {
    let obj = {
      id: blogId
    };
    this.isLoading = true;
    this._blogService.view(obj).subscribe((data) => {
      this.blogDetails = data["response"];
      console.log('All Blog Details:', this.blogDetails);
      this._global.addMetaInfo({ keyword: 'Blog,blog', title: this._global.getVal(this.blogDetails, 'name', ''), description: this._global.getVal(this.blogDetails, 'description', '') }, '');
      this.loadComments();
      this.isLoading = false;
      let params = {
        "component": this._global.getVal(data, 'request.component', ''),
        "application": this._global.getVal(data, 'request.application', ''),
        "entityId": this._global.getVal(data, 'response.id', ''),
        "action": 'view',
        "entityType": this._global.getVal(data, 'request.application', ''),
        "actionDetails": true
      }
      this._socialService.view(params).subscribe((socialView) => {
        if (socialView["status"] == "1") {}
        console.log('All socialView:', socialView);
      }, error => {
        console.log("error", JSON.stringify(error));
      });
    }, error => {
      this.isLoading = false;
      console.log("error", JSON.stringify(error));
    });
  }

  loadComments() {
    let p = {
      'component': this._global.COMPONENTS_SMN,
      'application': 'comment',
      'sortOrder': 'desc',
      'sortString': 'createdTime',
      "start": 0,
      "mediaId": this.blogId,
      "rows": 12
    };
    this._comment.comment(p).subscribe((data) => {
      if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
        this.comments = this._global.getVal(data, 'response.docs', []);
      }
      console.log('comments', data);
    }, error => {
      console.log("error", JSON.stringify(error));
    });
  }

  gotoLogin(app) {
    let loginurl = "/blogsdetails/" + this.blogId;
    this.router.navigate(['/login'], { queryParams: { returnUrl: loginurl, 'navigateByUrl': true } });
  }

  commentCreate() {
    this.comment_btn = true;
    let params = {
      rating: 0,
      mediaId: this.blogId,
      mediaName: this.blogDetails['name'],
      mediaType: 'blog',
      component: this._global.COMPONENTS_SMN,
      description: this.comment.description
    };
    this._comment.create(params).subscribe((data) => {
      this.comment_btn = false;
      if (data["status"] == "1") {
        this._global.showMessage({ "type": "success", "message": "Blog Created Success", "title": "" });
        this.loadComments();
      } else {
        for (let key in data["errors"]) {
          this.error[key] = data["errors"][key]["message"];
        }
      }
    }, error => {
      console.log(" reviewCreate error", JSON.stringify(error));
    });
  }
}
