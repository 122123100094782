import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GlobalService } from '../_common/global';
import { ApiService } from '../_services/api.service';
@Injectable({
  providedIn: 'root'
})
export class SignupService {
  constructor(private _global: GlobalService, private api: ApiService) { }

  
  signup(obj) {
    let params = new HttpParams();
    Object.entries(obj).forEach(([key, value]) => {
      let v = obj[key];
      params = params.append(key, v);
    });
    
    console.log(obj);   
  
    params = params.append('siteId', this._global.SITEID);
    params = params.append('orgId', this._global.ORGID);
    params = params.append('domainName', this._global.DOMAIN_NAME);
    params = params.append('sess_cookie', this._global.getToken());
     params = params.append('token', this._global.getToken());
    return this.api.call('member/register', params);
  }
}