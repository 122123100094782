<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="header-contact">
              <ul>
                <li>Welcome to Our store Royal Luxury Linen</li>
                <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: (+1) 732-649-3609</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 text-end">
            <ul class="header-dropdown">
              <!-- <li class="compare">
                <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
              </li>
              <li class="mobile-wishlist">
                <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
              </li> -->
              <li class="onhover-dropdown mobile-account">
                <i class="fa fa-user" aria-hidden="true"></i> 
                <span >{{member_img.name === 'Anonymous User' || member_img.name === '' ? 'My Account' : member_img.name}}</span>
                <ul class="onhover-show-div">
                  <li *ngIf="!isMember">
                    <a data-lng="en" [routerLink]="['/login']">
                      Login
                    </a>
                  </li>
                  <li *ngIf="!isMember">
                    <a data-lng="en" [routerLink]="['/signup']">
                      Sign Up
                    </a>
                  </li>
                  <li *ngIf="isMember">
                    <a data-lng="en" [routerLink]="['/help']">
                      Help
                    </a>
                  </li>
                  <li *ngIf="isMember">
                    <a data-lng="es" (click)="logout()">Logout</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main-menu">
            <div class="menu-left">
              <app-left-menu></app-left-menu>
              <div class="brand-logo">
                <a routerLink="/home" id="headerlogo">
                  <img [src]="themeLogo" class="img-fluid" alt="logo">
                </a>
              </div>
            </div>
            <div class="menu-right pull-right">
              <div>
                <app-menu></app-menu>
              </div>
              <div>
                <app-settings></app-settings>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  