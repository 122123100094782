<app-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-breadcrumb>
<!-- section start -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>Login</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup]="loginForm">
                        <div class="form-group mb-4">
                            <label for="email">Email</label>
                            <input type="text" class="form-control mb-0" id="email" placeholder="Enter your email" formControlName="email">
                            <div *ngIf="submitted && f['email'].errors" class="error-text mt-2">
                                <div *ngIf="f['email'].errors['required']">Email is required</div>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <label for="review">Password</label>
                            <input type="password" class="form-control mb-0" id="review" placeholder="Enter your password" formControlName="password">
                            <div *ngIf="submitted && f['password'].errors" class="error-text mt-2">
                                <div *ngIf="f['password'].errors['required']">Password is required</div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <button class="btn btn-solid login-btn-classes" (click)="loginRoyal()">Login</button>
                            <button class="btn btn-outline" (click)="reset()">Cancel</button>
                        </div>
                        <div>
                            <a class="anchor-tag" [routerLink]="['/forgot-password']" href="javascript:void(0)">Forget Password</a>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 right-login">
                <h3>New Customer</h3>
                <div class="theme-card authentication-right">
                    <h6 class="title-font">Create A Account</h6>
                    <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
                    <p>Don't have an account?</p>
                    <button class="btn btn-solid" (click)="goToSignUp()">Create an Account</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->