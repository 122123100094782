<ul class="product-social">
    <li>
      <a [href]="url" target="_blank">
        <i class="fa fa-facebook"></i>
      </a>
    </li>
    <li>
      <a [href]="url" target="_blank">
        <i class="fa fa-twitter"></i>
      </a>
    </li>
    <li>
      <a [href]="url" target="_blank">
        <i class="fa fa-linkedin"></i>
      </a>
    </li>
    <li>
      <a [href]="url" target="_blank">
        <i class="fa fa-reddit"></i>
      </a>
    </li>
    <li>
      <a [href]="url" target="_blank">
        <i class="fa fa-whatsapp"></i>
      </a>
    </li>
    <li>
      <a [href]="url" target="_blank">
        <i class="fa fa-telegram"></i>
      </a>
    </li>
    
    <li>
      <a [href]="url" target="_blank">
        <i class="fa fa-tumblr"></i>
      </a>
    </li>
</ul>