import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import * as _ from 'lodash';
import { GlobalService } from 'src/app/_common/global';
import { ApiService } from 'src/app/_services/api.service';
import { BrandsService } from 'src/app/_services/brands.service';
import { ShopifyService } from 'src/app/_services/shopify.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.jpg'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  public stick: boolean = false;
  public brands: any = [];
  public isMember = false;

  constructor(
    public local: LocalStorageService,
    public session: SessionStorageService,
    private _brandsService: BrandsService,
    private _shopifyService: ShopifyService,
    private _productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    public _global: GlobalService,
    private _apiService: ApiService
  ) {
    this.isMember = this._global.isMember();
    this._global.CARTSUMMARY = this._global.cartItems();
    let brand_item = this.local.get(this._global.SESSION_KEY + '_brands');
    if (brand_item != null && brand_item['brands']) {
      this.brands = brand_item['brands'];
    } else {
      this._brandsService.brand({}).subscribe(
        data => {
          if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
            this.brands = data["response"]["docs"];
            let item = {
              brands: this.brands
            };
            this.local.set(this._global.SESSION_KEY + '_brands', item, 60, 'm');
          }
        },
        error => {
          console.log("error", JSON.stringify(error));
        });
    }
  }

  ngOnInit(): void {
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

}
